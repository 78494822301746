import {
  Box,
  Spinner,
  IconButton,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import rf from 'src/requests/RequestFactory';

import { Table } from 'antd';
// import cn from 'classnames';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { toastError, toastSuccess } from 'src/utils/notify';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import AppModalConfirm from 'src/components/AppModalConfirm';
import AppButton from 'src/components/AppButton';
import { ModalAddAzureBot } from './ModalAddAzureBot';
import { IAzureBot, ISource } from 'src/types';
import ModalDetailAzureBot from './ModalDetailAzureBot';

function TableBots() {
  const [dataTable, setDataTable] = useState<IAzureBot[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModalAdd, setIsOpenModalAdd] = useState<boolean>(false);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<IAzureBot>();
  const [idDelete, setIdDelete] = useState<string>('');

  const fetchDataBots = async () => {
    setLoading(true);
    try {
      const res = await rf
        .getRequest('AzureRequest')
        .getAzureBots({ source: ISource.AZURE_OPENAI });

      setDataTable(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const deleteFile = async (id: string) => {
    setLoading(true);
    try {
      await await rf.getRequest('AzureRequest').deleteAzureBot(id);
      toastSuccess('Delete successfully!');
      setIdDelete('');
      await fetchDataBots();
    } catch (error) {
      console.log(error);
      toastError('Delete Failed!');
    }
    setLoading(false);
  };

  const columns: ColumnsType<IAzureBot> = [
    {
      title: 'Backend Method',
      dataIndex: 'backend_method',
      key: 'backend_method',
    },
    {
      title: ' Id',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: ' Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: ' Search index name',
      dataIndex: 'search_index_name',
      key: 'search_index_name',
    },
    {
      title: 'Create At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => {
        return moment(value).format('DD/MM/YYYY, h:mm:ss a');
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '_id',
      render: (id, data) => (
        <Flex>
          <Tooltip label="View Detail">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="info"
              onClick={() => {
                setDataDetail(data);
                setIsOpenModalDetail(true);
              }}
              icon={<InfoCircleOutlined />}
            />
          </Tooltip>
          <Tooltip label="Edit Bot">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="info"
              onClick={() => {
                setDataDetail(data);
                setIsOpenModalAdd(true);
              }}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip label="Delete">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="delete"
              onClick={() => setIdDelete(id)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const onOpenModal = () => {
    setIsOpenModalAdd(true);
  };

  const onCloseModal = (isRefresh?: boolean) => {
    setDataDetail(undefined);
    setIsOpenModalAdd(false);
    setIsOpenModalDetail(false);
    if (isRefresh) {
      fetchDataBots();
    }
  };

  useEffect(() => {
    fetchDataBots();
  }, []);

  return (
    <Box>
      <Box>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text fontWeight={700} margin={0} fontSize={'32px'}>
            Bots
          </Text>
          <AppButton
            onClick={onOpenModal}
            borderRadius={'6px'}
            variant="outline"
            mt={6}
            mb={3}
            isDisabled={loading}
          >
            New Bot
          </AppButton>
        </Flex>
        <Table
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Spinner />,
          }}
          pagination={{ pageSize: 5 }}
          dataSource={dataTable}
          rowKey={(el) => el._id}
        />
        {isOpenModalAdd && (
          <ModalAddAzureBot data={dataDetail} isOpen onClose={onCloseModal} />
        )}
        {isOpenModalDetail && dataDetail && (
          <ModalDetailAzureBot
            data={dataDetail}
            isOpen
            onClose={onCloseModal}
          />
        )}

        <AppModalConfirm
          isOpen={!!idDelete}
          onClose={() => setIdDelete('')}
          disabled={loading}
          onConfirm={() => deleteFile(idDelete)}
        />
      </Box>
    </Box>
  );
}

export default TableBots;
