/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useMemo } from 'react';
import Storage from 'src/utils/storage';
import { Configuration, OpenAIApi } from 'openai';

const OpenAIContext = createContext<{
  openAi: OpenAIApi;
}>({ openAi: {} as any });

class CustomFormData extends FormData {
  getHeaders() {
    return {};
  }
}
type CProps = {
  children: React.ReactNode;
};

export function OpenAIProvider({ children }: CProps) {
  const apiKey = Storage.getApiKey();

  const openAi: OpenAIApi = useMemo(() => {
    const configuration = new Configuration({
      apiKey,
      formDataCtor: CustomFormData,
    });
    const result = new OpenAIApi(configuration);
    return result;
  }, [apiKey]);

  return (
    <OpenAIContext.Provider value={{ openAi }}>
      {children}
    </OpenAIContext.Provider>
  );
}

export const useOpenAI = () => useContext(OpenAIContext);
