import { FC } from 'react';
import cn from 'classnames';
import { Box, Flex } from '@chakra-ui/react';

import { Outlet } from 'react-router-dom';
import styles from 'src/styles/layout/AppLayout.module.scss';
import Navbar from './Navbar';
import { OpenAIProvider } from 'src/context/OpenAIContext';
import AppBreadcrumb from 'src/components/AppBreadcrumb';

const AppLayout: FC = () => {
  return (
    <OpenAIProvider>
      <Flex height={'100vh'}>
        <Navbar />
        <Box className={cn(styles.main)} maxHeight={'100vh'}>
          <Box maxWidth={'1140px'} pt={'50px'} mx='auto'>
            <AppBreadcrumb />
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </OpenAIProvider>
  );
};

export default AppLayout;
