import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb';

export enum ERoutes {
  LOGIN = 'LOGIN',
  GOOGLE_CALLBACK = 'GOOGLE_CALLBACK',
  OPEN_AI_COMMON_FILES = 'OPEN_AI_COMMON_FILES',
  OPEN_AI_COMMON_BOTS = 'OPEN_AI_COMMON_BOTS',
  CHAT_GPT_3_FINE_TUNING = 'CHAT_GPT_3_FINE_TUNING',
  CHAT_GPT_3_EMBEDDING = 'CHAT_GPT_3_EMBEDDING',
  AZURE_SEARCH_INDICES = 'AZURE_SEARCH_INDICES',
  AZURE_FILES_MANAGEMENT = 'AZURE_FILES_MANAGEMENT',
  AZURE_BOTS = 'AZURE_BOTS',
}

export const ROUTES = {
  [ERoutes.LOGIN]: '/login',
  [ERoutes.GOOGLE_CALLBACK]: '/google/callback',
  [ERoutes.OPEN_AI_COMMON_FILES]: '/open-ai-common/files',
  [ERoutes.OPEN_AI_COMMON_BOTS]: '/open-ai-common/bots',
  [ERoutes.CHAT_GPT_3_FINE_TUNING]: '/open-ai-chat-gpt-3/fine-tuning',
  [ERoutes.CHAT_GPT_3_EMBEDDING]: '/open-ai-chat-gpt-3/embeddings',
  [ERoutes.AZURE_SEARCH_INDICES]: '/azure-cognitive-search/search-indices',
  [ERoutes.AZURE_FILES_MANAGEMENT]: '/azure-cognitive-search/files-management',
  [ERoutes.AZURE_BOTS]: '/azure-cognitive-search/bots',
};

export const BREADCRUMBS: {
  [key: string]: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
} = {
  [ERoutes.OPEN_AI_COMMON_FILES]: [
    {
      title: 'Open AI Common',
    },
    {
      title: 'Files',
      href: ROUTES.OPEN_AI_COMMON_FILES,
    },
  ],
  [ERoutes.OPEN_AI_COMMON_BOTS]: [
    {
      title: 'Open AI Common',
    },
    {
      title: 'Bots',
      href: ROUTES.OPEN_AI_COMMON_BOTS,
    },
  ],
  [ERoutes.CHAT_GPT_3_FINE_TUNING]: [
    {
      title: 'Open AI ChatGPT-3',
    },
    {
      title: 'Fine Tuning',
      href: ROUTES.CHAT_GPT_3_FINE_TUNING,
    },
  ],
  [ERoutes.CHAT_GPT_3_EMBEDDING]: [
    {
      title: 'Open AI ChatGPT-3',
    },
    {
      title: 'Embeddings',
      href: ROUTES.CHAT_GPT_3_EMBEDDING,
    },
  ],
  [ERoutes.AZURE_SEARCH_INDICES]: [
    {
      title: 'Azure Cognitive Search',
    },
    {
      title: 'Search Indices',
      href: ROUTES.AZURE_SEARCH_INDICES,
    },
  ],
  [ERoutes.AZURE_FILES_MANAGEMENT]: [
    {
      title: 'Azure Cognitive Search',
    },
    {
      title: 'Search Indices',
      href: ROUTES.AZURE_SEARCH_INDICES,
    },
    {
      title: 'Files Management',
    },
  ],
  [ERoutes.AZURE_BOTS]: [
    {
      title: 'Azure Cognitive Search',
    },
    {
      title: 'Bots',
      href: ROUTES.AZURE_BOTS,
    },
  ],
};
