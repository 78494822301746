/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICreateBotParams, IParams } from 'src/types';
import BaseRequest from './BaseRequest';
import config from 'src/config';

export default class ChatGptRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  getOpenAIEnv() {
    const url = `/api/chatgpt/admin/env/`;
    return this.get(url);
  }

  getAdminFiles() {
    const url = `/api/chatgpt/admin/files/`;
    return this.get(url);
  }
  uploadAdminFiles(payload: { file: any }) {
    const url = `/api/chatgpt/admin/files/`;
    return this.post(url, payload);
  }
  deleteAdminFile(id: string) {
    const url = `/api/chatgpt/admin/files/${id}`;
    return this.delete(url);
  }

  getAdminFineTunes() {
    const url = `/api/chatgpt/admin/fine-tunes/`;
    return this.get(url);
  }
  addAdminFineTunes(payload: { training_file_id: string; model: string }) {
    const url = `/api/chatgpt/admin/fine-tunes/`;
    return this.post(url, payload);
  }
  getAdminFineTuneById(id: string) {
    const url = `/api/chatgpt/admin/fine-tunes/${id}`;
    return this.get(url);
  }
  deleteAdminFineTune(id: string) {
    const url = `/api/chatgpt/admin/fine-tunes/${id}`;
    return this.delete(url);
  }
  cancelAdminFineTune(id: string) {
    const url = `/api/chatgpt/admin/fine-tunes/${id}/cancel`;
    return this.post(url, {});
  }

  getChatGptBots(params: IParams) {
    const url = `/api/admin/bots/`;
    return this.get(url, params);
  }

  createChatGptBots(params: ICreateBotParams) {
    const url = `/api/admin/bots/`;
    return this.post(url, params);
  }

  updateChatGptBots(id: string, params: ICreateBotParams) {
    const url = `/api/admin/bots/${id}`;
    return this.put(url, params);
  }

  deleteChatGptBots(id: string) {
    const url = `/api/admin/bots/${id}`;
    return this.delete(url);
  }

  getChatGptModels(params: { type: 'training' | 'bot_creation' }) {
    const url = `/api/chatgpt/admin/models/`;
    return this.get(url, params);
  }
}
