/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseRequest from './BaseRequest';
import config from 'src/config';
import queryString from 'query-string';
import { ICreateBotParams, IParams } from 'src/types';
export default class AzureRequest extends BaseRequest {
  getUrlPrefix() {
    return config.api.baseUrlApi;
  }

  getAzureFiles(params: {
    filename?: string;
    search_index_name: string;
    bot_id?: string;
  }) {
    const url = `/api/azure-openai/admin/files/`;
    return this.get(url, params);
  }
  uploadAzureFiles(payload: {
    file: any;
    search_index_name: string;
    bot_id?: string;
  }) {
    const url = `/api/azure-openai/admin/document-files/`;
    return this.post(url, payload);
  }

  deleteAzureFiles(payload: { filename: string; search_index_name: string }) {
    const stringified = queryString.stringify(payload);
    const url = `/api/azure-openai/admin/document-files/?${stringified}`;
    return this.delete(url);
  }

  getAzureBackEnds() {
    const url = `/api/azure-openai/admin/backends/`;
    return this.get(url);
  }

  getAzureSearchIndices() {
    const url = `/api/azure-openai/admin/search-indices/`;
    return this.get(url);
  }

  getAzureBots(params: IParams) {
    const url = `/api/admin/bots/`;

    return this.get(url, params);
  }

  createAzureBots(params: ICreateBotParams) {
    const url = `/api/admin/bots/`;
    return this.post(url, params);
  }

  updateAzureBot(id: string, params: ICreateBotParams) {
    const url = `/api/admin/bots/${id}`;
    return this.put(url, params);
  }

  deleteAzureBot(id: string) {
    const url = `/api/admin/bots/${id}`;
    return this.delete(url);
  }

  // Search Indices
  getSearchIndices() {
    const url = `/api/azure-openai/admin/search-indices/`;
    return this.get(url);
  }

  createSearchIndices(params: { index_name: string; language_code: string }) {
    const url = `/api/azure-openai/admin/search-indices/`;
    return this.post(url, params);
  }

  deleteSearchIndices(name: string) {
    const url = `/api/azure-openai/admin/search-indices/${name}`;
    return this.delete(url);
  }

  // language
  getLanguage() {
    const url = `/api/azure-openai/admin/languages/`;
    return this.get(url);
  }
}
