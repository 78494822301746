import {
  Box,
  Spinner,
  IconButton,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import { Table } from 'antd';
// import cn from 'classnames';
import moment from 'moment';
import FormUploadTrainingFile from './FormUploadTrainingFile';
import type { ColumnsType } from 'antd/es/table';
import { toastError, toastSuccess } from 'src/utils/notify';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ModalDetailFile from './ModalDetailFile';
import { OpenAIFile } from 'openai';
import { useOpenAI } from 'src/context/OpenAIContext';
import AppModalConfirm from 'src/components/AppModalConfirm';

function TableFiles() {
  const [dataTable, setDataTable] = useState<OpenAIFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<OpenAIFile>();
  const [idDelete, setIdDelete] = useState<string>('');

  const { openAi } = useOpenAI();

  const fetchDataFiles = async () => {
    setLoading(true);
    try {
      const res = await openAi.listFiles();

      setDataTable(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const deleteFile = async (id: string) => {
    setLoading(true);
    try {
      await openAi.deleteFile(id);
      toastSuccess('Delete successfully!');
      setIdDelete('');
      await fetchDataFiles();
    } catch (error) {
      console.log(error);
      toastError('Delete Failed!');
    }
    setLoading(false);
  };

  const columns: ColumnsType<OpenAIFile> = [
    {
      title: 'File Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Create At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => {
        return moment(value * 1000).format('DD/MM/YYYY, h:mm:ss a');
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id, data) => (
        <Flex>
          <Tooltip label="Delete">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="delete"
              onClick={() => setIdDelete(id)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
          <Tooltip label="View Detail">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="info"
              onClick={() => {
                setDataDetail(data);
                setIsOpenModal(true);
              }}
              icon={<InfoCircleOutlined />}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];
  const onCloseModal = () => {
    setDataDetail(undefined);
    setIsOpenModal(false);
  };
  const onSuccessUpload = () => {
    fetchDataFiles();
  };

  useEffect(() => {
    fetchDataFiles();
  }, []);

  return (
    <Box >
      <Box>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text fontWeight={700} margin={0} fontSize={'32px'}>
            Training Files
          </Text>
        </Flex>
        <FormUploadTrainingFile isOpen onSuccess={onSuccessUpload} />
        <Table
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Spinner />,
          }}
          pagination={{ pageSize: 5 }}
          dataSource={dataTable}
          rowKey={(el) => el.id}
        />
        <ModalDetailFile
          isOpen={isOpenModal}
          onClose={onCloseModal}
          data={dataDetail}
        />
        <AppModalConfirm
          isOpen={!!idDelete}
          disabled={loading}
          onClose={() => setIdDelete('')}
          onConfirm={() => deleteFile(idDelete)}
        />
      </Box>
    </Box>
  );
}

export default TableFiles;
