import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import { OpenAIFile } from 'openai';
import { useState, useEffect } from 'react';
import { useOpenAI } from 'src/context/OpenAIContext';

type CProps = {
  isOpen: boolean;
  data?: OpenAIFile;
  onClose: () => void;
};

export default function ModalDetailFile({ data, isOpen, onClose }: CProps) {
  const [dataContent, setDataContent] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { openAi } = useOpenAI();

  const handleCloseModal = () => {
    onClose();
    setDataContent('');
  };

  const fetchDataFileContent = async () => {
    if (!data?.id) return;
    setLoading(true);
    try {
      const res = await openAi.downloadFile(data?.id);

      setDataContent(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataFileContent();
  }, [data]);

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>File Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <Box pb={1}>ID: {data?.id} </Box>
            <Box pb={1}>FileName: {data?.filename} </Box>
            <Box pb={1}>Byte: {data?.bytes} </Box>
            <Box pb={1}>Purpose: {data?.purpose} </Box>
            <Box pb={1}>
              Create at:{' '}
              {moment((data?.created_at || 0) * 1000).format(
                'DD/MM/YYYY, h:mm:ss a',
              )}{' '}
            </Box>
            <Box pb={1}>Content: </Box>

            <TextArea
              readOnly
              value={loading ? 'Loading...' : dataContent}
              rows={15}
              placeholder="Lorem iprum"
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
