/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import styles from 'src/styles/page/OpenAICommon.module.scss';
import TableBots from './components/TableOpenAIBots';

function OpenAICommonBot() {
  return (
    <Box position={'relative'} className={cn(styles.openAICommonContainer)}>
      <TableBots />
    </Box>
  );
}

export default OpenAICommonBot;
