import { Box, Flex, Image } from '@chakra-ui/react';
import styles from 'src/styles/layout/AppLayout.module.scss';
import cn from 'classnames';
import {
  SmileOutlined,
  RobotOutlined,
  // SettingOutlined,
  // DingtalkOutlined,
  BulbOutlined,
  // CoffeeOutlined,
  // FireOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { logo } from 'src/assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERoutes, ROUTES } from 'src/constants';
import { getRouteByPath } from 'src/utils/route';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
  getItem('Open AI Common', 'open-ai-common', <RobotOutlined />, [
    getItem('Files', ROUTES.OPEN_AI_COMMON_FILES),
    getItem('Bots', ROUTES.OPEN_AI_COMMON_BOTS),
  ]),
  getItem('Open AI ChatGPT-3', 'open-ai-chat-gpt-3', <SmileOutlined />, [
    // getItem('Embeddings', ROUTES.CHAT_GPT_3_EMBEDDING),
    getItem('Fine-tuning', ROUTES.CHAT_GPT_3_FINE_TUNING),
  ]),
  // getItem('Open AI ChatGPT-3.5', 'open-ai-chat-gpt-new', <DingtalkOutlined />, [
  //   getItem('Embeddings', '/open-ai-chat-gpt-new/embeddings'),
  //   getItem('Fine-tuning', '/open-ai-chat-gpt-new/fine-tuning'),
  // ]),
  // getItem('Azure OpenAI', 'azure-ai', <FireOutlined />, [
  //   getItem('Embeddings', '/azure-ai/embeddings'),
  //   getItem('Fine-tuning', '/azure-ai/fine-tuning'),
  // ]),
  getItem(
    'Azure Cognitive Search',
    'azure-cognitive-search',
    <BulbOutlined />,
    [
      getItem('Files Management', ROUTES.AZURE_SEARCH_INDICES),
      getItem('Bots', ROUTES.AZURE_BOTS),
    ],
  ),
  // getItem('Google Bard', 'google-bard', <CoffeeOutlined />),
  // getItem('Sotatek AI,etc...', 'sotatek-ai', <SettingOutlined />),
];

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedKey = () => {
    const routeName = getRouteByPath(pathname);

    if (routeName === ERoutes.AZURE_FILES_MANAGEMENT) {
      return [ROUTES.AZURE_SEARCH_INDICES];
    }
    return [ROUTES[routeName]];
  };

  return (
    <Flex flexDirection={'column'} className={cn(styles.navbar)}>
      <Image src={logo} w={'120px'} py={4} mx={'40px'} alt="logo" />
      <Box flex={'1 1 0'} overflowY={'auto'} maxH={'full'} pl={4}>
        <Menu
          selectedKeys={selectedKey()}
          defaultOpenKeys={[
            'open-ai-common',
            'open-ai-chat-gpt-3',
            'open-ai-chat-gpt-new',
            'azure-ai',
            'azure-cognitive-search',
          ]}
          mode="inline"
          items={items}
          onClick={(el) => {
            const { key } = el;
            navigate(`${key}`);
          }}
        />
      </Box>
    </Flex>
  );
};
export default Navbar;
