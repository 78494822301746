export interface IFileAzure {
  filename: string;
  creation_time: Date;
  nopages: number;
}
export enum EApproach {
  RRR = 'RRR',
  RTR = 'RTR',
}
export interface IApproachDetail {
  approach: EApproach;
  prompt_template: string;
  query_prompt_template: string;
  follow_up_questions_prompt: string;
}

export interface IBackEndAzure {
  method: string;
  approaches: EApproach[];
  approach_details: IApproachDetail[];
}

export interface ISearchIndices {
  name: string;
}

export interface ILanguage {
  language: string;
  code: string;
}

export interface IAzureBot {
  _id: string;
  source: string;
  name: string;
  backend_method: string;
  backend_approach: string;
  search_index_name: string;
  user_id: string;
  created_at: Date;
  prompt_template: string;
  query_prompt_template: string;
  follow_up_questions_prompt: string;
}
