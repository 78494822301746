import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useOpenAI } from 'src/context/OpenAIContext';
import TextArea from 'antd/es/input/TextArea';
import { FineTune } from 'openai';
type CProps = {
  isOpen: boolean;
  data?: FineTune;
  onClose: () => void;
};

export function ModalDetailFineTune({ data, isOpen, onClose }: CProps) {
  const [dataContent, setDataContent] = useState<string>();

  const handleCloseModal = () => {
    setDataContent('');
    onClose();
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { openAi } = useOpenAI();

  const fetchDataFileContent = async () => {
    if (!data?.id) return;
    setLoading(true);
    try {
      const res = await openAi.retrieveFineTune(data?.id);

      setDataContent(JSON.stringify(res.data, null, 2));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataFileContent();
  }, [data]);

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fine Tune Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <TextArea
              readOnly
              value={loading ? 'Loading...' : dataContent}
              rows={30}
              placeholder="Fine Tune Detail"
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
