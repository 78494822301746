import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import rf from 'src/requests/RequestFactory';
import queryString from 'query-string';
import { AuthLayout } from 'src/layouts';
import { Flex, Spinner } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setUserAuth, setApiKey, clearUser } from 'src/store/myAccount';
import { useAppSelector } from 'src/hooks/hooks';

function GoogleCallBack() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { auth } = useAppSelector((state) => state.myAccount);
  const dispatch = useDispatch();
  const parsed = queryString.parse(search);

  const getAccessToken = async () => {
    try {
      const res = await rf.getRequest('AuthRequest').getLoginAccessToken({
        code: parsed?.code || '',
      });
      const accessToken: string = res.data.access_token;

      dispatch(setUserAuth({ accessToken }));
    } catch (e) {
      dispatch(clearUser());
      navigate('/');
      console.log(e, 'Error');
    }
  };

  const fetchApiKey = async () => {
    try {
      const response = await rf.getRequest('ChatGptRequest').getOpenAIEnv();
      const { OPENAI_API_KEY } = response.data;

      dispatch(setApiKey({ apiKey: OPENAI_API_KEY }));
      navigate('/');
    } catch (error) {
      dispatch(clearUser());
      navigate('/');
      console.log(error, 'Error');
    }
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    if (auth.accessToken) {
      fetchApiKey();
    }
  }, [auth.accessToken]);

  return (
    <AuthLayout>
      <Flex
        height={150}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Spinner size="xl" mb={6} /> Please wait...
      </Flex>
    </AuthLayout>
  );
}

export default GoogleCallBack;
