/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthLayout } from 'src/layouts';
import rf from 'src/requests/RequestFactory';
import cn from 'classnames';
import AppButton from 'src/components/AppButton';
import { Box, Image, Text } from '@chakra-ui/react';
import { LogoSingle } from 'src/assets/images';
import styles from 'src/styles/page/Login.module.scss';
import { icGoogle } from 'src/assets/icon';

function LogIn() {
  const onLoginGoogle = async () => {
    try {
      const res = await rf.getRequest('AuthRequest').getLoginGoogle();
      const url: string = res.data.url;
      window.location.href = url;
    } catch (e) {
      console.log(e, 'Error');
    }
  };

  const _renderEmailPart = () => {
    return (
      <>
        <Text className={cn(styles.title)} textAlign={'center'}>
          Login
        </Text>

        <AppButton
          onClick={onLoginGoogle}
          borderRadius={'6px'}
          size={'lg'}
          width={'full'}
          className={cn(styles.btn_login)}
          variant="outline"
          mt={6}
          mb={3}
        >
          <Image src={icGoogle} pr={2} alt="logo" />
          Continue with Google
        </AppButton>
      </>
    );
  };

  return (
    <AuthLayout>
      <Box className={cn(styles.containerWrapper)}>
        <Image mx={'auto'} src={LogoSingle} pr={2} alt="logo" />
        {_renderEmailPart()}
      </Box>
    </AuthLayout>
  );
}

export default LogIn;
