import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import rf from 'src/requests/RequestFactory';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { toastError, toastSuccess } from 'src/utils/notify';
import AppSelect from 'src/components/AppSelect';
import {
  EApproach,
  IAzureBot,
  IBackEndAzure,
  ISearchIndices,
  ISource,
} from 'src/types';
import AppInput from 'src/components/AppInput';
import TextArea from 'antd/es/input/TextArea';

type CProps = {
  isOpen: boolean;
  data?: IAzureBot;
  onClose: (isRefresh?: boolean) => void;
};

interface IFormValues {
  name: string;
  backend_method: string;
  search_index_name: string;
  prompt_template?: string;
  query_prompt_template?: string;
  follow_up_questions_prompt?: string;
}

const defaultValues: IFormValues = {
  name: '',
  backend_method: '',
  search_index_name: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('This is required'),
  backend_method: yup.string().nullable().required('This is required'),
  search_index_name: yup.string().nullable().required('This is required'),
});

export function ModalAddAzureBot({ isOpen, onClose, data }: CProps) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<IFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [dataBackEnds, setDataBackEnds] = useState<IBackEndAzure[]>([]);
  const [dataSearchIndices, setDataSearchIndices] = useState<ISearchIndices[]>(
    [],
  );
  const watchBackendMethod = watch('backend_method');

  const handleCloseModal = () => {
    !loading && onClose();
  };

  const detailApproaches = useMemo(() => {
    const selectedBackend = dataBackEnds?.find(
      (el) => el.method === watchBackendMethod,
    );
    if (!selectedBackend) return null;
    const { approach_details } = selectedBackend;
    const approach_detail = approach_details[0];
    return approach_detail;
  }, [watchBackendMethod, dataBackEnds]);

  const optionBackEnds = dataBackEnds?.map((el) => ({
    value: `${el.method}`,
    label: el.method,
  }));

  const optionSearchIndices = dataSearchIndices?.map((el) => ({
    value: el.name,
    label: el.name,
  }));

  const handleChangeBackEnd = (value: string) => {
    const selectedBackend = dataBackEnds?.find((el) => el.method === value);
    if (!selectedBackend) return;
    const { approach_details } = selectedBackend;
    const approach_detail = approach_details[0];

    setValue('prompt_template', approach_detail.prompt_template);
    setValue('query_prompt_template', approach_detail.query_prompt_template);
    setValue(
      'follow_up_questions_prompt',
      approach_detail.follow_up_questions_prompt,
    );
  };

  const onHandleSubmit = async (dataForm: IFormValues) => {
    const {
      backend_method,
      name,
      search_index_name,
      follow_up_questions_prompt,
      prompt_template,
      query_prompt_template,
    } = dataForm;

    if (!detailApproaches) return;
    const form = {
      source: ISource.AZURE_OPENAI,
      name,
      backend_method,
      backend_approach: detailApproaches?.approach || '',
      search_index_name,
      follow_up_questions_prompt,
      prompt_template,
      query_prompt_template,
    };
    setLoading(true);
    try {
      if (data) {
        await rf.getRequest('AzureRequest').updateAzureBot(data._id, form);
        toastSuccess('Update bot azure successfully!');
      } else {
        await rf.getRequest('AzureRequest').createAzureBots(form);
        toastSuccess('Add bot azure successfully!');
      }
      onClose(true);
    } catch (error) {
      console.log(error, 'error');
      if (data) {
        toastError((error as string) || 'Update bot azure failed!');
      } else {
        toastError((error as string) || 'Add bot azure failed!');
      }
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await rf.getRequest('AzureRequest').getAzureBackEnds();
      const resp = await rf.getRequest('AzureRequest').getAzureSearchIndices();
      setDataBackEnds(res.data);
      setDataSearchIndices(resp.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data) {
      const {} = data;
      setValue('name', data.name);
      setValue('backend_method', data.backend_method);
      setValue('search_index_name', data.search_index_name);
      setValue('prompt_template', data.prompt_template);
      setValue('query_prompt_template', data.query_prompt_template);
      setValue('follow_up_questions_prompt', data.follow_up_questions_prompt);
    }
  }, [data]);

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <ModalContent>
            <ModalHeader>{data ? 'Update bot' : 'Create bot'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl display={'flex'} isInvalid={!!errors.name}>
                <FormLabel w={220} pt={2} pr={4}>
                  {'Name'}
                </FormLabel>
                <Box flex={1}>
                  <AppInput
                    width={'100%'}
                    control={control}
                    size="large"
                    placeholder="Enter name"
                    name="name"
                  />
                  <FormErrorMessage>
                    {errors.name && errors?.name.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                mt={3}
                display={'flex'}
                isInvalid={!!errors.backend_method}
              >
                <FormLabel w={220} pt={2} pr={4}>
                  {'Backend'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    control={control}
                    disabled={loading}
                    placeholder="Select backend"
                    onChange={handleChangeBackEnd}
                    size="large"
                    name="backend_method"
                    options={optionBackEnds}
                  />
                  <FormErrorMessage>
                    {errors.backend_method && errors?.backend_method.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                mt={3}
                display={'flex'}
                isInvalid={!!errors.search_index_name}
              >
                <FormLabel w={220} pt={2} pr={4}>
                  {'Search Index'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    disabled={!!data || loading}
                    control={control}
                    placeholder="Select search index name"
                    size="large"
                    name="search_index_name"
                    options={optionSearchIndices}
                  />
                  <FormErrorMessage>
                    {errors.search_index_name &&
                      errors?.search_index_name.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              {detailApproaches?.approach && (
                <FormControl
                  mt={3}
                  display={'flex'}
                  flexWrap={'wrap'}
                  isInvalid={!!errors.prompt_template}
                >
                  <FormLabel w={220} pt={2} pr={4}>
                    {'Prompt template'}
                  </FormLabel>
                  <Box flex={1}>
                    <AppInput
                      width={'100%'}
                      disabled={loading}
                      control={control}
                      placeholder="Enter prompt template"
                      size="large"
                      name="prompt_template"
                    />
                    <FormErrorMessage>
                      {errors.prompt_template &&
                        errors?.prompt_template.message}
                    </FormErrorMessage>
                    <Box>
                      <FormLabel pt={2} pr={4}>
                        {'Example: '}
                      </FormLabel>
                      <TextArea
                        readOnly
                        value={detailApproaches.prompt_template}
                        rows={4}
                        maxLength={6}
                      />
                    </Box>
                  </Box>
                </FormControl>
              )}
              {detailApproaches?.approach === EApproach.RRR && (
                <>
                  <FormControl
                    mt={3}
                    display={'flex'}
                    flexWrap={'wrap'}
                    isInvalid={!!errors.query_prompt_template}
                  >
                    <FormLabel w={220} pt={2} pr={4}>
                      {'Query prompt template'}
                    </FormLabel>
                    <Box flex={1}>
                      <AppInput
                        width={'100%'}
                        disabled={loading}
                        control={control}
                        placeholder="Enter query prompt template"
                        size="large"
                        name="query_prompt_template"
                      />
                      <FormErrorMessage>
                        {errors.query_prompt_template &&
                          errors?.query_prompt_template.message}
                      </FormErrorMessage>
                      <Box>
                        <FormLabel pt={2} pr={4}>
                          {'Example: '}
                        </FormLabel>
                        <TextArea
                          readOnly
                          value={detailApproaches.query_prompt_template}
                          rows={4}
                          maxLength={6}
                        />
                      </Box>
                    </Box>
                  </FormControl>
                  <FormControl
                    mt={3}
                    display={'flex'}
                    flexWrap={'wrap'}
                    isInvalid={!!errors.follow_up_questions_prompt}
                  >
                    <FormLabel w={220} pr={4}>
                      {'Follow up questions prompt'}
                    </FormLabel>
                    <Box flex={1}>
                      <AppInput
                        width={'100%'}
                        disabled={loading}
                        control={control}
                        placeholder="Enter follow up questions prompt"
                        size="large"
                        name="follow_up_questions_prompt"
                      />
                      <FormErrorMessage>
                        {errors.follow_up_questions_prompt &&
                          errors?.follow_up_questions_prompt.message}
                      </FormErrorMessage>
                      <Box>
                        <FormLabel pt={2} pr={4}>{'Example: '}</FormLabel>
                        <TextArea
                          readOnly
                          value={detailApproaches.follow_up_questions_prompt}
                          rows={4}
                          maxLength={6}
                        />
                      </Box>
                    </Box>
                  </FormControl>
                </>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                isDisabled={loading}
                onClick={handleCloseModal}
                variant="ghost"
              >
                Close
              </Button>
              <Button
                type="submit"
                isDisabled={loading}
                borderRadius={'6px'}
                colorScheme="blue"
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
