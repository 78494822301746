import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import rf from 'src/requests/RequestFactory';
import type { UploadFile } from 'antd';
import { useForm } from 'react-hook-form';
import { toastError, toastSuccess } from 'src/utils/notify';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import AppDraggerPDF from 'src/components/AppDraggerPDF';

type CProps = {
  isOpen: boolean;
  searchIndexName: string;
  onSuccess: () => void;
};

type FormValues = {
  file: UploadFile[];
};

export default function FormUploadAzureFile({
  onSuccess,
  searchIndexName,
}: CProps) {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({});
  const [loading, setLoading] = useState<boolean>(false);
  const watchFile = watch('file');
  const onHandleSubmit = async (data: FormValues) => {
    const { file } = data;
    const formData = new FormData();
    file.forEach((dataFile) => {
      formData.append(`file[]`, dataFile.originFileObj as RcFile);
    });
    formData.append('search_index_name', searchIndexName);
    setLoading(true);
    try {
      await rf.getRequest('AzureRequest').uploadAzureFiles(formData);

      toastSuccess('Upload file training azure successfully!');
      onSuccess();
      reset();
    } catch (error) {
      toastError('Upload file training azure failed!');
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Box>
          <>
            <FormControl isInvalid={!!errors.file} isRequired>
              <FormLabel>{'File input'}</FormLabel>

              <AppDraggerPDF
                accept="application/pdf"
                control={control}
                name="file"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </AppDraggerPDF>

              <FormErrorMessage>
                {errors.file && errors?.file.message}
              </FormErrorMessage>
            </FormControl>
          </>

          <Flex justifyContent={'end'}>
            <Button
              my={6}
              type="submit"
              isDisabled={loading || !watchFile?.length}
              borderRadius={'6px'}
              colorScheme="blue"
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </form>
    </>
  );
}
