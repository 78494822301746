import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import AppButton from './AppButton';

interface CProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  disabled?: boolean;
  title?: string;
  description?: string;
}

export default function AppModalConfirm({
  isOpen,
  onClose,
  onConfirm,
  disabled,
  title = 'Confirm',
  description = 'Are you sure you want to proceed with the delete action? ',
}: CProps) {
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{description}</ModalBody>

          <ModalFooter>
            <AppButton
              variant="outline"
              isDisabled={disabled}
              mr={3}
              onClick={onClose}
            >
              Close
            </AppButton>

            <AppButton isDisabled={disabled} onClick={onConfirm}>
              Confirm
            </AppButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
