import { Select, SelectProps } from 'antd';
import cn from 'classnames';
import styles from 'src/styles/components/Common.module.scss';

import { Control, Controller } from 'react-hook-form';

interface CProps extends SelectProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  width?: number | string;
}

const AppSelect: React.FC<CProps> = (props) => {
  const {
    control,
    name,
    children,
    className,
    onChange: onChangeAnt,
    width = 200,
    ...others
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Select
            className={cn(styles.appSelect, className)}
            value={value}
            onChange={(el, option) => {
              onChange(el);
              onChangeAnt?.(el, option);
            }}
            style={{ width }}
            status={error?.message ? 'error' : undefined}
            getPopupContainer={(trigger) => trigger.parentNode}
            {...others}
          >
            {children}
          </Select>
        </>
      )}
    />
  );
};

export default AppSelect;
