import {
  Box,
  Spinner,
  IconButton,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { useState, useEffect } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import AppButton from 'src/components/AppButton';
import type { ColumnsType } from 'antd/es/table';
import { ModalAddFineTunes } from './ModalAddFineTunes';
import { toastError, toastSuccess } from 'src/utils/notify';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons';
import { useOpenAI } from 'src/context/OpenAIContext';
import { ModalDetailFineTune } from './ModalDetailFineTune';
import { FineTune } from 'openai';
import AppModalConfirm from 'src/components/AppModalConfirm';

function TableFineTunes() {
  const [dataTable, setDataTable] = useState<FineTune[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModalAdd, setIsOpenModalAdd] = useState<boolean>(false);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<FineTune>();
  const [idDelete, setIdDelete] = useState<string>('');
  const [idCancel, setIdCancel] = useState<string>('');

  const { openAi } = useOpenAI();

  const fetchDataFineTunes = async () => {
    setLoading(true);
    try {
      const res = await openAi.listFineTunes();
      setDataTable(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const deleteFineTuneModel = async (id: string) => {
    setLoading(true);
    try {
      await openAi.deleteModel(id);
      toastSuccess('Delete model successfully!');
      setIdDelete('');
      await fetchDataFineTunes();
    } catch (error) {
      console.log(error);
      toastError('Delete model Failed!');
    }
    setLoading(false);
  };

  const cancelFineTuneJob = async (id: string) => {
    setLoading(true);
    try {
      await await openAi.cancelFineTune(id);
      toastSuccess('Cancel fine tune successfully!');
      setIdCancel('');
      fetchDataFineTunes();
    } catch (error) {
      console.log(error);
      toastError('Cancel fine tune Failed!');
    }
    setLoading(false);
  };

  const columns: ColumnsType<FineTune> = [
    {
      title: 'File Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Fine-tune model',
      dataIndex: 'fine_tuned_model',
      key: 'fine_tuned_model',
    },

    {
      title: 'Create At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => {
        return moment(value * 1000).format('DD/MM/YYYY, h:mm:ss a');
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'id',
      render: (id, data) => (
        <Flex>
          {data.status === 'succeeded' && (
            <Tooltip label="Delete Model">
              <IconButton
                isDisabled={loading}
                variant={'unstyled'}
                aria-label="delete"
                onClick={() => setIdDelete(id)}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
          {data.status === 'pending' && (
            <Tooltip label="Cancel Job">
              <IconButton
                isDisabled={loading}
                variant={'unstyled'}
                aria-label="cancel"
                onClick={() => setIdCancel(id)}
                // onClick={() => cancelFineTuneJob(id)}
                icon={<CloseSquareOutlined />}
              />
            </Tooltip>
          )}
          <Tooltip label="View Detail">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="info"
              onClick={() => {
                setDataDetail(data);
                setIsOpenModalDetail(true);
              }}
              icon={<InfoCircleOutlined />}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const onOpenModal = () => {
    setIsOpenModalAdd(true);
  };

  const onCloseModal = (isRefresh?: boolean) => {
    setIsOpenModalAdd(false);
    if (isRefresh) {
      fetchDataFineTunes();
    }
  };

  const onCloseModalDetail = () => {
    setIsOpenModalDetail(false);
    setDataDetail(undefined);
  };

  useEffect(() => {
    fetchDataFineTunes();
  }, []);

  return (
    <Box >
      <Box>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text fontWeight={700} margin={0} fontSize={'32px'}>
            Fine Tunes
          </Text>
          <AppButton
            onClick={onOpenModal}
            borderRadius={'6px'}
            variant="outline"
            mt={6}
            mb={3}
            isDisabled={loading}
          >
            New Fine tune
          </AppButton>
        </Flex>
        <Table
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Spinner />,
          }}
          rowKey={(el) => el.id}
          pagination={{ pageSize: 5 }}
          dataSource={dataTable}
        />
      </Box>
      {isOpenModalAdd && <ModalAddFineTunes isOpen onClose={onCloseModal} />}
      <ModalDetailFineTune
        isOpen={isOpenModalDetail}
        onClose={onCloseModalDetail}
        data={dataDetail}
      />

      <AppModalConfirm
        isOpen={!!idDelete || !!idCancel}
        disabled={loading}
        onClose={() => {
          setIdDelete('');
          setIdCancel('');
        }}
        description={
          idDelete
            ? 'Are you sure you want to proceed with the delete action? '
            : 'Are you sure you want to proceed with the cancel action? '
        }
        onConfirm={() => {
          if (idCancel) {
            cancelFineTuneJob(idDelete);
          } else {
            deleteFineTuneModel(idDelete);
          }
        }}
      />
    </Box>
  );
}

export default TableFineTunes;
