/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import styles from 'src/styles/page/Azure.module.scss';
import TableAzureSearchIndices from './components/TableAzureSearchIndices';

function AzureSearchIndices() {
  return (
    <Box position={'relative'} className={cn(styles.azureFileContainer)}>
      <TableAzureSearchIndices />
    </Box>
  );
}

export default AzureSearchIndices;
