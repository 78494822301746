import cn from 'classnames';
import styles from 'src/styles/components/Common.module.scss';

import { Control, Controller } from 'react-hook-form';
import { InputProps } from 'antd/es/input';
import Input from 'antd/es/input/Input';

interface CProps extends InputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
}

const AppInput: React.FC<CProps> = (props) => {
  const {
    control,
    name,
    children,
    className,
    onChange: onChangeAnt,
    ...others
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Input
            className={cn(styles.appInput, className)}
            value={value}
            onChange={(event) => {
              onChange(event.target.value);
              onChangeAnt?.(event);
            }}
            status={error?.message ? 'error' : undefined}
            {...others}
          >
            {children}
          </Input>
        );
      }}
    />
  );
};

export default AppInput;
