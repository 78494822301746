/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import styles from 'src/styles/page/OpenAICommon.module.scss';
import TableFiles from './components/TableFiles';

function OpenAICommonFiles() {
  return (
    <Box position={'relative'} className={cn(styles.FilesContainer)}>
      <TableFiles />
    </Box>
  );
}

export default OpenAICommonFiles;
