import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import moment from 'moment';
import PDFView from 'src/components/PDFView';
import { IFileAzure } from 'src/types';

type CProps = {
  isOpen: boolean;
  data?: IFileAzure;
  search_indices: string;
  onClose: () => void;
};

export default function ModalDetailAzureFile({
  data,
  isOpen,
  onClose,
  search_indices,
}: CProps) {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <>
      <Modal size={'6xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent height={800}>
          <ModalHeader>File Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDir={'column'} pb={4}>
            <Box pb={1}>FileName: {data?.filename} </Box>
            <Box pb={1}>
              Create at:{' '}
              {moment(data?.creation_time).format('DD/MM/YYYY, h:mm:ss a')}{' '}
            </Box>
            <Box pb={1}>Content: </Box>

            <Box flex={'1 1 0'} height={'700px'}>
              {!!data && (
                <PDFView
                  search_indices={search_indices}
                  filename={data.filename}
                  pageNumber={data.nopages}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
