import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import rf from 'src/requests/RequestFactory';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IAzureBot, IFileAzure } from 'src/types';
import PDFView from 'src/components/PDFView';

type CProps = {
  isOpen: boolean;
  data?: IAzureBot;
  onClose: () => void;
};

export default function ModalDetailAzureBot({ data, isOpen, onClose }: CProps) {
  const [dataFiles, setDataFiles] = useState<IFileAzure[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataFileDetail, setDataFileDetail] = useState<IFileAzure>();

  const fetchDataFiles = async () => {
    setLoading(true);
    try {
      const res = await rf
        .getRequest('AzureRequest')
        .getAzureFiles({ search_index_name: data?.search_index_name });

      setDataFiles(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataFiles();
  }, []);

  return (
    <>
      <Modal size={'5xl'} isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent height={800}>
          <ModalHeader>Bot Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDir={'column'} pb={4}>
            <Box pb={1}>Id: {data?._id} </Box>
            <Box pb={1}>Name: {data?.name} </Box>
            <Box pb={1}>Search index name: {data?.search_index_name} </Box>
            <Box pb={1}>Backend approach: {data?.backend_approach} </Box>
            <Box pb={1}>Backend method: {data?.backend_method} </Box>
            <Box pb={1}>
              Create at:{' '}
              {moment(data?.created_at).format('DD/MM/YYYY, h:mm:ss a')}{' '}
            </Box>
            <Box pb={1}>Files: </Box>
            {loading && (
              <Box mx="auto">
                <Spinner />
              </Box>
            )}
            {!loading && dataFiles.length && (
              <Box height={dataFileDetail ? 150 : 440} overflow={'auto'}>
                {dataFiles.map((file) => (
                  <Box
                    onClick={() => setDataFileDetail(file)}
                    key={file.filename}
                    fontWeight={
                      file.filename === dataFileDetail?.filename
                        ? 'bold'
                        : 'normal'
                    }
                    cursor={'pointer'}
                  >
                    {' '}
                    - {file.filename}
                  </Box>
                ))}
              </Box>
            )}
            <Box flex={'1 1 0'} height={'600px'}>
              {!!dataFileDetail && data && (
                <PDFView
                  search_indices={data.search_index_name}
                  filename={dataFileDetail.filename}
                  pageNumber={dataFileDetail.nopages}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
