import { ReactNode } from 'react';
import { Upload } from 'antd';
import { Control, Controller } from 'react-hook-form';

type FileUploadProps = {
  children?: ReactNode;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  accept?: string;
};

const { Dragger } = Upload;

const AppDragger = (props: FileUploadProps) => {
  const { control, name, accept = '.jsonl', children } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <Dragger
            accept={accept}
            multiple={false}
            onChange={(el) => {
              const fileList = el.fileList.length ? [el.file] : [];
              onChange(fileList);
            }}
            beforeUpload={() => {
              return false;
            }}
            fileList={value}
          >
            {children}
          </Dragger>
        );
      }}
    />
  );
};

export default AppDragger;
