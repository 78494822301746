import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {  IOpenAIBot } from 'src/types';
import { useOpenAI } from 'src/context/OpenAIContext';
import { Model } from 'openai';
import TextArea from 'antd/es/input/TextArea';

type CProps = {
  isOpen: boolean;
  data?: IOpenAIBot;
  onClose: () => void;
};

export default function ModalDetailOpenAIBot({
  data,
  isOpen,
  onClose,
}: CProps) {
  const [dataModel, setDataModel] = useState<Model>();
  const [loading, setLoading] = useState<boolean>(false);
  const { openAi } = useOpenAI();

  const handleCloseModal = () => {
    onClose();
  };

  const fetchDataFiles = async () => {
    setLoading(true);
    if (!data?.model_id) return;
    try {
      const res = await openAi.retrieveModel(data?.model_id);

      setDataModel(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataFiles();
  }, []);

  return (
    <>
      <Modal size={'5xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent height={800}>
          <ModalHeader>Bot Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDir={'column'} pb={4}>
            <Box pb={1}>Id: {data?._id} </Box>
            <Box pb={1}>Name: {data?.name} </Box>
            <Box pb={1}>Model ID: {data?.model_id} </Box>
            <Box pb={1}>Model method: {data?.model_method} </Box>
            <Box pb={1}>
              Create at:{' '}
              {moment(data?.created_at).format('DD/MM/YYYY, h:mm:ss a')}{' '}
            </Box>
            <Box pb={1}>Model: </Box>
            <TextArea
              readOnly
              value={
                loading ? 'Loading...' : JSON.stringify(dataModel, null, 2)
              }
              rows={20}
              placeholder="Model detail"
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
