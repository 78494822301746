import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import rf from 'src/requests/RequestFactory';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { toastError, toastSuccess } from 'src/utils/notify';
import AppSelect from 'src/components/AppSelect';
import { IModel, IOpenAIBot, ISource } from 'src/types';
import AppInput from 'src/components/AppInput';

type CProps = {
  isOpen: boolean;
  data?: IOpenAIBot;
  onClose: (isRefresh?: boolean) => void;
};

interface IFormValues {
  name: string;
  model_id: string;
}

const defaultValues: IFormValues = {
  name: '',
  model_id: '',
};

const schema = yup.object().shape({
  name: yup.string().nullable().required('This is required'),
  model_id: yup.string().nullable().required('This is required'),
});

export function ModalAddOpenAIBot({ isOpen, data, onClose }: CProps) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [dataModels, setDataFiles] = useState<IModel[]>([]);

  const handleCloseModal = () => {
    !loading && onClose();
  };

  const onHandleSubmit = async (dataForm: IFormValues) => {
    const { model_id, name } = dataForm;

    const findBackend = dataModels?.find((el) => el.id === model_id);

    const form = {
      source: ISource.CHATGPT,
      name,
      model_method: findBackend?.method,
      model_id,
    };

    setLoading(true);
    try {
      if (data) {
        await rf.getRequest('ChatGptRequest').updateChatGptBots(data._id, form);
        toastSuccess('Update bot chat gpt successfully!');
      } else {
        await rf.getRequest('ChatGptRequest').createChatGptBots(form);
        toastSuccess('Add bot chat gpt successfully!');
      }

      onClose(true);
    } catch (error) {
      console.log(error, 'error');
      if (data) {
        toastError((error as string) || 'Update bot chat gpt failed!');
      } else {
        toastError((error as string) || 'Add bot chat gpt failed!');
      }
    }
    setLoading(false);
  };

  const fetchDataModel = async () => {
    setLoading(true);
    try {
      const res = await rf
        .getRequest('ChatGptRequest')
        .getChatGptModels({ type: 'bot_creation' });

      setDataFiles(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataModel();
  }, []);

  useEffect(() => {
    if (data) {
      setValue('name', `${data.name}`);
      setValue('model_id', `${data.model_id}`);
    }
  }, [data]);

  const optionModels = dataModels?.map((el) => ({
    value: el.id,
    label: el.id,
  }));

  return (
    <>
      <Modal size={'4xl'} isOpen={isOpen} isCentered onClose={handleCloseModal}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <ModalContent>
            <ModalHeader>{data ? 'Update bot' : 'Create bot'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl display={'flex'} isInvalid={!!errors.name}>
                <FormLabel w={120} pt={2} pr={4}>
                  {'Name'}
                </FormLabel>
                <Box flex={1}>
                  <AppInput
                    width={'100%'}
                    control={control}
                    size="large"
                    placeholder="Enter name"
                    name="name"
                  />
                  <FormErrorMessage>
                    {errors.name && errors?.name.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl
                mt={3}
                display={'flex'}
                isInvalid={!!errors.model_id}
              >
                <FormLabel w={120} pt={2} pr={4}>
                  {'Model'}
                </FormLabel>
                <Box flex={1}>
                  <AppSelect
                    width={'100%'}
                    control={control}
                    disabled={!!data || loading}
                    placeholder="Select model"
                    size="large"
                    name="model_id"
                    options={optionModels}
                  />
                  <FormErrorMessage>
                    {errors.model_id && errors?.model_id.message}
                  </FormErrorMessage>
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                mr={3}
                isDisabled={loading}
                onClick={handleCloseModal}
                variant="ghost"
              >
                Close
              </Button>
              <Button
                type="submit"
                isDisabled={loading}
                borderRadius={'6px'}
                colorScheme="blue"
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
