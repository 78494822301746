import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Flex alignItems={'center'} justifyContent={'center'} h={'full'}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button 
          onClick={() => navigate('/')} 
          type="primary">
            Back Home
          </Button>
        }
      />
    </Flex>
  );
};

export default NotFound;
