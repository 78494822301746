import {
  Box,
  Spinner,
  IconButton,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import rf from 'src/requests/RequestFactory';

import { Table } from 'antd';
// import cn from 'classnames';
import type { ColumnsType } from 'antd/es/table';
import { toastError, toastSuccess } from 'src/utils/notify';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import AppModalConfirm from 'src/components/AppModalConfirm';
import AppButton from 'src/components/AppButton';
import { ISearchIndices } from 'src/types';
import { ModalAddSearchIndices } from './ModalAddSearchIndices';
import { useNavigate } from 'react-router-dom';

function TableAzureSearchIndices() {
  const [dataTable, setDataTable] = useState<ISearchIndices[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenModalAdd, setIsOpenModalAdd] = useState<boolean>(false);
  const [idDelete, setIdDelete] = useState<string>('');
  const navigate = useNavigate();

  const fetchDataSearchIndices = async () => {
    setLoading(true);
    try {
      const res = await rf.getRequest('AzureRequest').getSearchIndices();

      setDataTable(res.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const deleteSearchIndices = async (name: string) => {
    setLoading(true);
    try {
      await await rf.getRequest('AzureRequest').deleteSearchIndices(name);
      toastSuccess('Delete search indices successfully!');
      setIdDelete('');
      await fetchDataSearchIndices();
    } catch (error) {
      console.log(error);
      toastError('Delete Failed!');
    }
    setLoading(false);
  };

  const columns: ColumnsType<ISearchIndices> = [
    {
      title: ' Name',
      dataIndex: 'name',
      key: 'name',
      width: '80%',
    },

    {
      title: 'Action',
      key: 'action',
      dataIndex: 'name',
      render: (name) => (
        <Flex>
          <Tooltip label="Delete">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="delete"
              onClick={() => setIdDelete(name)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
          <Tooltip label="View Detail">
            <IconButton
              isDisabled={loading}
              variant={'unstyled'}
              aria-label="info"
              onClick={() => {
                navigate(`/azure-cognitive-search/files-management/${name}`);
              }}
              icon={<InfoCircleOutlined />}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const onOpenModal = () => {
    setIsOpenModalAdd(true);
  };

  const onCloseModal = (isRefresh?: boolean) => {
    setIsOpenModalAdd(false);
    if (isRefresh) {
      fetchDataSearchIndices();
    }
  };

  useEffect(() => {
    fetchDataSearchIndices();
  }, []);

  return (
    <Box >
      <Box>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text fontWeight={700} margin={0} fontSize={'32px'}>
            Search Indices
          </Text>
          <AppButton
            onClick={onOpenModal}
            borderRadius={'6px'}
            variant="outline"
            mt={6}
            mb={3}
            isDisabled={loading}
          >
            New Search Indices
          </AppButton>
        </Flex>
        <Table
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Spinner />,
          }}
          pagination={{ pageSize: 5 }}
          dataSource={dataTable}
          rowKey={(el) => el.name}
        />
        {isOpenModalAdd && (
          <ModalAddSearchIndices isOpen onClose={onCloseModal} />
        )}

        <AppModalConfirm
          isOpen={!!idDelete}
          disabled={loading}
          onClose={() => setIdDelete('')}
          onConfirm={() => deleteSearchIndices(idDelete)}
        />
      </Box>
    </Box>
  );
}

export default TableAzureSearchIndices;
