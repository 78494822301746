/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import styles from 'src/styles/page/OpenAICommon.module.scss';
import TableAzureBots from './components/TableAzureBots';

function AzureBots() {
  return (
    <Box position={'relative'} className={cn(styles.openAICommonContainer)}>
      <TableAzureBots />
    </Box>
  );
}

export default AzureBots;
