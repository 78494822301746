import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import RouteHistory from 'src/routes';
import { Provider } from 'react-redux';
import theme from 'src/themes';
import React from 'react';
import 'src/styles/global.scss';
import configureStore from 'src/store';
import config from './config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'antd/dist/reset.css';


const clientId = config.auth.googleClientId;

function App() {
  const { store } = configureStore();

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode="light" />
          <React.StrictMode>
            <BrowserRouter>
              <RouteHistory />
            </BrowserRouter>
            <ToastContainer
              position="top-right"
              hideProgressBar
              pauseOnHover
              closeButton
              toastStyle={{
                position: 'relative',
                overflow: 'visible',
              }}
            />
          </React.StrictMode>
        </ChakraProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
