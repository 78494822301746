export interface IChatGptFile {
  object: string;
  id: string;
  purpose: string;
  filename: string;
  bytes: number;
  created_at: number;
  status: string;
  status_details: string;
}

export interface IFile {
  object: string;
  id: string;
  purpose: string;
  filename: string;
  bytes: number;
  created_at: number;
  status: string;
  status_details: null;
}

export interface IFineTunes {
  object: string;
  id: string;
  hyperparams: IHyperparams;
  organization_id: string;
  model: string;
  training_files: IFile[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validation_files: any[];
  result_files: File[];
  created_at: number;
  updated_at: number;
  status: string;
  fine_tuned_model: string;
}

export interface IHyperparams {
  n_epochs: number;
  batch_size: number;
  prompt_loss_weight: number;
  learning_rate_multiplier: number;
}

export interface IModel {
  id: string;
  method: string;
}

export interface IOpenAIBot {
  _id: string;
  source: string;
  name: string;
  model_id: string;
  model_method: string;
  user_id: string;
  created_at: Date;
}

export enum ISource {
  CHATGPT = 'CHATGPT',
  AZURE_OPENAI = 'AZURE_OPENAI',
}

export interface IParams {
  page?: number;
  limit?: string;
  source?: ISource;
}

export interface ICreateBotParams {
  source: ISource;
  name: string;
  model_id?: string;
  model_method?: string;
  backend_method?: string;
  backend_approach?: string;
  search_index_name?: string;
  prompt_template: string;
  query_prompt_template: string;
  follow_up_questions_prompt: string;
}
