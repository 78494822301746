import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { Box, Flex, IconButton, Spinner } from '@chakra-ui/react';
import Input from 'antd/es/input/Input';
import cn from 'classnames';
import { useEffect, FC, useState, useMemo } from 'react';
import config from 'src/config';
import styles from 'src/styles/page/Azure.module.scss';
import { isInt } from 'src/utils/format';

interface PDFViewProps {
  filename: string;
  pageNumber: number;
  search_indices: string;
}

const domainApi = config.api.baseUrlApi;

const PDFView: FC<PDFViewProps> = ({
  filename,
  search_indices,
  pageNumber,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [fileName, setFileName] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isFocusInput, setIsFocusInput] = useState<boolean>(false);

  const url = useMemo(() => {
    if (!fileName) {
      return null;
    }
    return `${domainApi}/api/azure-openai/admin/search-indices/${search_indices}/content/${fileName}#toolbar=0`;
  }, [fileName, search_indices]);

  const handleLoaded = () => {
    setLoading(false);
  };

  const onNextPage = () => {
    if (currentPage === pageNumber) return;
    setCurrentPage(currentPage + 1);
  };

  const onPrevPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (isFocusInput) return;
    const name = filename.replace('.pdf', '');

    setFileName(`${name}-${currentPage - 1}.pdf`);
  }, [currentPage, isFocusInput, filename]);

  useEffect(() => {
    if (!url) return;
    setLoading(true);
  }, [url]);

  const _renderToolbar = () => {
    return (
      <Flex alignItems={'center'} justifyContent={'center'}>
        <IconButton
          onClick={onPrevPage}
          isDisabled={loading || currentPage === 1}
          variant={'unstyled'}
          aria-label="Search database"
          icon={<StepBackwardOutlined />}
        />

        <Input
          disabled={loading}
          value={currentPage}
          type="number"
          onFocus={() => {
            setIsFocusInput(true);
          }}
          onChange={(event) => {
            const value = Number(event.target.value);
            if (isInt(value) && value <= pageNumber) {
              setCurrentPage(value);
            }
          }}
          onBlur={() => {
            if (!currentPage) {
              setCurrentPage(1);
            }
            setIsFocusInput(false);
          }}
          style={{ width: 50 }}
        />
        <Box pl={2}> / {pageNumber}</Box>
        <IconButton
          onClick={onNextPage}
          isDisabled={loading || currentPage === pageNumber}
          variant={'unstyled'}
          aria-label="Search database"
          icon={<StepForwardOutlined />}
        />
      </Flex>
    );
  };

  return (
    <Flex flexDir={'column'} w={'full'} h={'full'}>
      <Box flex={'1 1 0'} position={'relative'}>
        {_renderToolbar()}
        {url && (
          <embed
            src={url}
            key={fileName}
            type="application/pdf"
            style={{ width: '100%', height: '100%' }}
            onLoad={handleLoaded}
          />
        )}
        {loading && (
          <Flex
            alignItems={'center'}
            color={'black'}
            position={'absolute'}
            className={cn(styles.loadingPDF)}
          >
            <Spinner mr={4} /> Loading ....
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default PDFView;
