import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import LogIn from './pages/login';
import PrivateRoute from './components/PrivateRoute';
import { AppLayout } from './layouts';
import OpenAICommonFiles from './pages/open-ai-common/Files';
import OpenAICommonBot from './pages/open-ai-common/OpenAiBots';
import AzureBots from './pages/azure-cognitive-search/AzureBots';
import OpenAIChatGpt3FineTuning from './pages/open-ai-chatpgt-3/FineTuning';
// import OpenAIChatGpt3Embedding from './pages/open-ai-chatpgt-3/Embedding';
import AzureSearchIndices from './pages/azure-cognitive-search/SearchIndices';
import AzureFileManagement from './pages/azure-cognitive-search/FileAzureManagement';
import GoogleCallBack from './pages/google-callback';
import Storage from 'src/utils/storage';
import { clearUser } from './store/myAccount';
import AuthRoute from './components/AuthRoute';
import NotFound from './pages/not-found';
import { useAppDispatch } from './hooks/hooks';
import { useColorMode } from '@chakra-ui/react';
import { ROUTES } from './constants';

/**
 * Main App routes.
 */

const RouteHistory = () => {
  const { pathname } = useLocation();
  const accessToken = Storage.getAccessToken();
  const dispatch = useAppDispatch();
  const { colorMode, toggleColorMode } = useColorMode();
  const isExpireTimeToken =
    Storage.getExpireTimeToken() &&
    new Date().getTime() >= Number(Storage.getExpireTimeToken());

  // scroll to top when route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!accessToken || isExpireTimeToken) {
      dispatch(clearUser());
      return;
    }
  }, [accessToken]);

  useEffect(() => {
    if (colorMode === 'light') return;
    toggleColorMode();
  }, [colorMode]);

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.LOGIN}
          element={<AuthRoute element={<LogIn />} />}
        />
        <Route
          path={ROUTES.GOOGLE_CALLBACK}
          element={<AuthRoute element={<GoogleCallBack />} />}
        />
        <Route path="/" element={<AppLayout />}>
          <Route
            index
            element={<PrivateRoute element={<OpenAICommonFiles />} />}
          />
          <Route
            path={ROUTES.OPEN_AI_COMMON_FILES}
            element={<PrivateRoute element={<OpenAICommonFiles />} />}
          />
          <Route
            path={ROUTES.OPEN_AI_COMMON_BOTS}
            element={<PrivateRoute element={<OpenAICommonBot />} />}
          />
          <Route
            path={ROUTES.CHAT_GPT_3_FINE_TUNING}
            index
            element={<PrivateRoute element={<OpenAIChatGpt3FineTuning />} />}
          />
          {/* <Route
            path={ROUTES.CHAT_GPT_3_EMBEDDING}
            index
            element={<PrivateRoute element={<OpenAIChatGpt3Embedding />} />}
          /> */}

          <Route
            path={ROUTES.AZURE_SEARCH_INDICES}
            index
            element={<PrivateRoute element={<AzureSearchIndices />} />}
          />
          <Route
            path={`${ROUTES.AZURE_FILES_MANAGEMENT}/:name`}
            index
            element={<PrivateRoute element={<AzureFileManagement />} />}
          />
          <Route
            path={ROUTES.AZURE_BOTS}
            index
            element={<PrivateRoute element={<AzureBots />} />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

/**
 * Wrap the app routes into router
 *
 * PROPS
 * =============================================================================
 * @returns {React.Node}
 */
export default RouteHistory;
