import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import type { UploadFile } from 'antd';
import { useForm } from 'react-hook-form';
import { toastError, toastSuccess } from 'src/utils/notify';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { useOpenAI } from 'src/context/OpenAIContext';
import AppDragger from 'src/components/AppDragger';

type CProps = {
  isOpen: boolean;
  onSuccess: () => void;
};

type FormValues = {
  file: UploadFile[];
};

export default function FormUploadTrainingFile({ onSuccess }: CProps) {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({});
  const watchFile = watch('file');
  const [loading, setLoading] = useState<boolean>(false);
  const { openAi } = useOpenAI();

  const onHandleSubmit = async (data: FormValues) => {
    const { file } = data;

    setLoading(true);
    try {
      await openAi.createFile(file?.[0] as RcFile, 'fine-tune');
      toastSuccess('Upload file training successfully!');
      onSuccess();
      reset();
    } catch (error) {
      toastError('Upload file training failed!');
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Box>
          <>
            <FormControl isInvalid={!!errors.file} isRequired>
              <FormLabel>{'File input'}</FormLabel>

              <AppDragger control={control} name="file">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </AppDragger>

              <FormErrorMessage>
                {errors.file && errors?.file.message}
              </FormErrorMessage>
            </FormControl>
          </>

          <Flex justifyContent={'end'}>
            <Button
              my={6}
              type="submit"
              isDisabled={loading || !watchFile?.length}
              borderRadius={'6px'}
              colorScheme="blue"
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </form>
    </>
  );
}
