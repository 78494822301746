/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import React from 'react';
import styles from 'src/styles/page/OpenAIChatGpt.module.scss';
import TableFineTunes from './components/TableFineTunes';

function OpenAIChatGpt3FineTuning() {
  return (
    <Box position={'relative'} className={cn(styles.OpenAIChatGptContainer)}>
      <TableFineTunes />
    </Box>
  );
}

export default OpenAIChatGpt3FineTuning;
